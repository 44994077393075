import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
import '@/assets/theme.less'
import '@/assets/global.css'
import '@/assets/iconfont/iconfont.css'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI, { size: 'mini' })

// Vue.prototype.BASE_URL =
//   'http://' +
//   (process.env.NODE_ENV === 'development-' ? 'localhost' : '150.158.162.224')

Vue.prototype.$deepCopy = function (obj) {
  return JSON.parse(JSON.stringify(obj))
}
import VueCodemirror from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
Vue.use(VueCodemirror)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
