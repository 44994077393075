<template>
  <div class="admin">
    <div class="header">
      <routerNav></routerNav>
      <imagesView v-show="$route.fullPath == '/index'"></imagesView>
      <el-image v-show="$route.fullPath != '/index'" class="img" fit="cover" :src="require('@/assets/image/12.jpg')">
      </el-image>
    </div>
    <div class="center" :style="{
      'flex-direction': $store.state.menuToRight ? 'row-reverse' : 'row',
    }">
      <div class="rightContainer">
        <routerContent></routerContent>
      </div>
    </div>
    <div class="footer">
      <footerView @changeImg="open"></footerView>
    </div>
    <el-dialog :before-close="handleClose" style="text-align: center;" :visible.sync="dialogVisible"
      :width="imageIndex ? '40%' : '60%'">
      <el-image :src="require(`@/assets/image/${imageIndex ? 21 : 20}.png`)" style="height: 100%;"></el-image>
    </el-dialog>
  </div>
</template>
<script>
import routerContent from './content/index'
import routerNav from './nav/index'
import footerView from './footer/index'
import imagesView from './images/index'
export default {
  components: { routerContent, routerNav, footerView, imagesView },
  data () {
    return {
      dialogVisible: false,
      imageIndex: 0
    }
  },
  methods: {
    open (i) {
      this.dialogVisible = true,
        this.imageIndex = i
    },
    handleClose (done) {
      done();
    }
  }
}
</script>
<style lang="less" scoped>
.admin {
  min-width: 1100px;
  min-height: 100vh;
  transition: background-color 0.3s var(--bezier);
  overflow: hidden;
  background: #f0f2f5;
}

.header {
  min-width: 900px;
  padding: 80px 100px 0;
  box-sizing: border-box;
}

.center {
  display: flex;
  min-width: 1100px;
  padding: 0 20%;
  box-sizing: border-box;

  .rightContainer {
    width: 100%;
  }
}

.img {
  height: 200px;
  width: 100%;
}

.footer {
  max-width: 100vw;
  min-width: 1100px;
  height: 300px;
  background-color: #000;
}
</style>