<script>
import { vLoading } from "element-ui";
export default {
  data () {
    return {
      routeIsAlive: false
    }
  },
  watch: {
    visitedRoutes: {
      handler () {
        this.updateKeepAliveNameList()
      },
    },
  },
  created () {
    this.changeIsAlive()
    console.log(this.$route)
  },
  methods: {
    changeIsAlive () {
      this.routeIsAlive = true
      let timer = setTimeout(() => {
        this.routeIsAlive = false
        clearTimeout(timer)
      }, 2000);
    }
  }
}
</script>
<template>
  <router-view>
    <transition name="zoom-fade">
      <keep-alive>
        <component :is="$route.Component" :key="$route.fullPath" v-loading="routeIsAlive" mode="out-in"></component>
      </keep-alive>
    </transition>
  </router-view>
</template>
<style lang="less" scoped>
.top-fade-leave-active {
  animation: fadeTopOut 0.8s ease-in-out;
}

@keyframes fadeTopIn {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  50% {
    transform: translateY(0px);
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeTopOut {
  0% {
    opacity: 1;
  }

  50% {
    transform: translateY(20px);
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

//zoom-fade

.zoom-fade-enter-active {
  animation: fadeZoomIn 1.2s;
}

.zoom-fade-leave-active {
  animation: fadeZoomOut 0.6s ease-in-out;
}

@keyframes fadeZoomIn {
  0% {
    opacity: 0;
    transform: scale(0.01, 0.01);
  }

  50% {
    transform: scale(1);
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeZoomOut {
  0% {
    opacity: 1;
  }

  50% {
    transform: scale(1.02);
  }

  100% {
    opacity: 0;
  }
}
</style>