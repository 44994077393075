import Vue from 'vue'
import Router from 'vue-router'
import layouts from '@/layouts/index.vue'
Vue.use(Router)

const viewport = {
  content: 'width=device-width, initial-scale=1.0, user-scalable=no',
}

const router = new Router({
  //mode: 'history',
  //base: __dirname,
  routes: [
    {
      path: '/',
      redirect: '/index',
    },
    {
      path: '/',
      component: layouts,
      meta: {
        title: '首页',
      },
      children: [
        {
          path: 'index',
          name: 'Index',
          component: () => import('@/views/index.vue'),
          meta: {
            title: '首页',
            noClosable: true,
          },
        },
      ],
    },
    {
      path: '/',
      component: layouts,
      meta: {
        title: 'DCI 申领',
      },
      children: [
        {
          path: 'dci/index',
          name: 'Dci',
          component: () => import('@/views/dci/index.vue'),
          meta: {
            title: 'DCI 申领',
            noClosable: true,
          },
        },
      ],
    },
    {
      path: '/',
      component: layouts,
      meta: {
        title: '服务内容',
      },
      children: [
        {
          path: 'server/index',
          name: 'Server',
          component: () => import('@/views/server/index.vue'),
          meta: {
            title: '服务内容',
            noClosable: true,
          },
        },
      ],
    },
    {
      path: '/',
      component: layouts,
      meta: {
        title: '联系我们',
      },
      children: [
        {
          path: 'contact/index',
          name: 'Contact',
          component: () => import('@/views/contact/index.vue'),
          meta: {
            title: '联系我们',
            noClosable: true,
          },
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.meta.content) {
    let head = document.getElementByTagName('head')
    let meta = document.createElemnet('meta')
    meta.name = 'viewport'
    meta.content = 'width=device-width, initial-scale=1.0, user-scalable=no'
    head[0].appendChild(meta)
  }
  next()
  sessionStorage.setItem('router-path', to.path)
})

export default router
