<template>
  <div class="footerPage">
    <div>
      <div>产品与服务</div>
      <div class="text" @click="to">DCI 申领</div>
      <div class="text" @click="toPages(0)">短剧备案</div>
      <div class="text" @click="toPages(1)">短剧推广</div>
      <div class="text" @click="toPages(2)">短剧模板</div>
    </div>
    <div>
      <div>联系我们</div>
      <div class="text">业务咨询：jlcs2024@qq.com</div>
      <div class="text">联系电话：13147647737</div>
    </div>
    <div>
      <div>资质证书</div>
      <div class="text" @click="open(1)">增值电信业务许可证编号：(吉)B2-20240065</div>
      <div class="licence text" @click="open(0)">
        营业执照：
        <el-image :src="require('@/assets/image/20.png')" style="width: 120px;"></el-image>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data () {
    return {
      dialogVisible: false
    };
  },
  mounted () { },
  methods: {
    toPages (index) {
      this.$router.push({ path: `/server/index?i=${index}` }).catch(err => { })
    },
    to () {
      this.$router.push({ path: `/dci/index` }).catch(err => { })
    },
    open (i) {
      this.$emit('changeImg', i)
    },
    handleClose (done) {
      done();
    }
  },
};
</script>
<style lang="less" scoped>
.footerPage {
  display: flex;
  color: #fff;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  justify-content: space-evenly;

  .text {
    margin-top: 10px;
  }

  .licence {
    display: flex;
    align-items: flex-start;
  }
}
</style>