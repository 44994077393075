<template>
  <div>
    <el-carousel height="400px">
      <el-carousel-item v-for="(item, index) in imageList" :key="item">
        <div class="itemImage">
          <el-image class="img" fit="cover" :src="item">
          </el-image>
          <div v-show="!index" class="itemImage-text">
            <div class="back"></div>
            <div class="value">
              <div class="title">安全稳定企业服务</div>
              <div class="content">多年服务经验积累，提供多种安全保障和服务场景，<br />
                优质服务值得您信赖</div>
              <div>
                <el-button
                  style="width: 150px;height: 40px;background-color: #fff;color: rgb(56, 148, 255);border: none;"
                  type="primary" @click="tocontact">联系我们</el-button>
              </div>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      imageList: [
        require('@/assets/image/12.jpg'), require('@/assets/image/19.jpg')
      ]
    };
  },
  created () { },
  methods: {
    tocontact () {
      this.$router.push('/contact/index')
    }
  },
};
</script>
<style lang="less" scoped>
.itemImage {
  position: relative;
  height: 400px;

  .img {
    width: 100%;
    height: 100%;
  }

  &-text {
    // background-color: rgba(56, 148, 255, 0.5);
    width: 55%;
    height: 100%;
    padding-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    box-sizing: border-box;
    left: 0;
    top: 0;
    z-index: 2;
    color: #fff;

    .value {
      position: relative;
      z-index: 2;
    }

    .back {
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(56, 148, 255, 0.5);
    }

    .content {
      margin: 20px 0;
    }

    .title {
      font-size: 30px;
      font-weight: 700;
    }
  }
}
</style>