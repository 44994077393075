<script>
export default {
  data () {
    return {
      style: null,
      opacity: 1,
      navList: [
        { name: '首页', path: '/index', isIcon: false },
        { name: 'DCI 申领', path: '/dci/index', isIcon: true },
        { name: '解决方案' },
        { name: '联系我们', path: '/contact/index', isIcon: true }
      ]
    }
  },
  mounted () {
    window.addEventListener("scroll", this.windowScroll); //监听页面滚动
  },
  destroyed () {
    window.removeEventListener("scroll", this.windowScroll); //销毁滚动事件
  },
  methods: {
    windowScroll () {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      let opacity = Math.abs(Math.round(scrollTop)) / 100;
      this.style = { background: `rgba(255, 255, 255,${opacity})`, color: opacity ? '#999' : '#000' }
    },
    toPages (url) {
      this.$router.push(url).catch(err => { })
    },
    toScroll () {
      if (this.$route.fullPath != '/index') {
        this.$router.push('/index')
        let timer = setTimeout(() => {
          document.documentElement.scrollTop = 480
          clearTimeout(timer)
        }, 500);
      } else document.documentElement.scrollTop = 480
    }
  }
}
</script>
<template>
  <div class="nav" :style="[style]">
    <div class="nav-image">
      <span style="color: #409EFF;font-size: 24px;">创实</span>科技
      <!-- <el-image :src="require('@/assets/image/navlogo.jpg')" style="width: 60px; height: 60px"></el-image> -->
    </div>
    <div v-for="(item, index) in navList" :key="index" class="nav-text"
      :class="{ selectTab: $route.fullPath == item.path }" @click="item.path ? toPages(item.path) : toScroll()">{{
        item.name }}
      <i v-show="item.isIcon" class="el-icon-arrow-down"></i>
    </div>
  </div>
</template>
<style lang="less" scoped>
.nav {
  width: 100%;
  min-width: 900px;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  padding: 0 100px;
  box-sizing: border-box;

  &-image {
    width: 300px;
  }

  &-text {
    cursor: pointer;
  }
}

.selectTab {
  color: #409EFF;
  position: relative;
  top: -5px;
  font-weight: 600;
}

.nav:hover {
  background: rgba(255, 255, 255, 1);
  color: #000
}
</style>