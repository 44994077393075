<template>
  <div id="app">
    <transition mode="out-in" name="router-fade">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="less">
@import "@/assets/global";

:focus {
  outline: -webkit-focus-ring-color auto 0px;
}

body {
  margin: 0;
  min-width: 900px;
  background-color: #f5f6f6;
}

body,
html {
  margin: 0;
  height: 100%;
}

ul {
  padding: 0;
  margin: 0;

  li {
    list-style-type: none;
  }
}

.item-desc {
  font-size: small;
  color: #5e5e5e;
}

.max-fill {
  width: 100% !important;
}
</style>
