import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menuToRight: true,
    serIndex: 0
  },
  getters: {
  },
  mutations: {
    setSerIndex (state, val) {
      state.serIndex = val
    }
  },
  actions: {
  },
  modules: {},
})
